import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

Vue.use(Vuex);
export default function createStore(env) {
  return new Vuex.Store({
    actions,
    mutations,
    getters,
    state() {
      return {
        _env: env,
        _overlays: {
          showCatalog: false,
          showMobileMenu: false,
        },
        _modals: [],
        _status: {
          // Статусы заказа
          CODE_ORDER_SUCCESS: 2, // оплачено обработка заказа
          CODE_ORDER_IN_DELIVERY: 6, // в пути
          CODE_ORDER_DELIVERED: 7, // доставлено
        },
        _types: {
          // Способы связи
          ADDRESS: 1,
          PHONE: 2,
          SOCIAL: 3,
          EMAIL: 4,
          // Информация на странице маркетплейса
          BANNER: 1, // Преимущества
          ADVANTAGE: 2, // Преимущества
          WHAT_YOU_PAYING: 3, // За что вы платите
          WHAT_YOU_NEED_TO_CONNECT: 4, // Что нужно для подключения
          // Схемы работ
          SHOWCASE_SHIPPING: 1, // Витрина + Доставка
          SHOWCASE_FULFILLMENT: 2, // Витрина + Фулфилмент
          ORDER_AND_PICK_UP: 3, // Закажи и забери
          DELIVERY_SELLER: 4, // Доставка силами продавца
          SELLER: 11, // Продавец
          SASA: 12, // САСА
          BUYER: 13, // Покупатель
          // Типы продаж
          RETAIL: 1, // розничная
          STEP: 2, // по n товаров
          WHOLESALE: 3, // комплектами
          NOT_SELLING: 4, // не продается
        },
        exchange_rates: {},
        categories: [],
        cities: [],
        pages: [],
        marketplace: {},
        auth: {
          user: null,
          cart: [], // товары в корзине
          orders: [], // все заказы, чтобы подсчитать сумму
          orders_paginate: {}, // заказы в лк
          deliveries: [], // способы доставки
          favorites: [], // все избранные, чтобы вывести в хедере
          favorites_paginate: {}, // избранные в лк
        },
        home_page: {
          main_categories: [],
          banner_shares: [],
          main_shares: [],
          good_deal_products: {},
          popular_products: {},
          new_products: {},
          marketplace: {},
          main_brands: [],
        },
        product_page: {
          product: {},
        },
        catalog_page: {
          products: {},
          category: {},
          filter_attributes: [],
          products_min_max_price: {},
        },
        shares_page: {
          shares: {},
        },
        share_page: {
          share: {},
          products: {},
        },
        shops_page: {
          shops: {},
          all_categories: [],
        },
        shop_page: {
          shop: {},
          good_deal_products: {},
        },
        marketplace_page: {
          info: [],
          work_schemes: [],
        },
        faq_page: {
          faqs: [],
        },
        static_page: {},
      };
    },
  });
}
