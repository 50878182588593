<template>
  <div id="app">
    <vue-progress-bar />
    <HeaderComponent />
    <Transition mode="out-in" name="page">
      <main class="main" v-show="canShowPage">
        <template v-for="(page, i) in $router.currentRoute.matched">
          <Component :is="page.components.default" :key="i + key + path" />
        </template>
      </main>
    </Transition>
    <main v-if="!canShowPage">
      <Transition mode="out-in" name="page">
        <CatalogMenuComponent v-show="$store.state._overlays.showCatalog" />
      </Transition>
      <Transition mode="out-in" name="page">
        <MobileMenuComponent v-show="$store.state._overlays.showMobileMenu" />
      </Transition>
    </main>
    <FooterComponent v-show="!$store.state._overlays.showCatalog" />
    <Transition name="bounce">
      <ModalComponent />
    </Transition>
    <Notifications position="bottom right" />
    <CookiesComponent />
  </div>
</template>

<script>
import ModalComponent from "components/modals/index.vue";
import HeaderComponent from "components/header/index.vue";
import FooterComponent from "components/footer/index.vue";
import CatalogMenuComponent from "components/catalog_menu/index.vue";
import MobileMenuComponent from "components/MobileMenuComponent.vue";
import CookiesComponent from "components/CookiesComponent.vue";

export default {
  data() {
    return {
      key: 0,
      path: "",
    };
  },
  created() {
    // Полоска загрузки
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      this.$store.state._overlays.showCatalog = false;
      this.$store.state._overlays.showMobileMenu = false;
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach((to) => {
      this.$nextTick().then(() => {
        this.$Progress.finish();
        this.key += 1;
        this.path = to.fullPath;
      });
    });
  },
  mounted() {
    this.$Progress.finish();
    // если пользователь не авторизован, то берем товары в корзине из localStorage
    if (!this.$store.state.apollo_token) {
      this.$store.state.auth.cart = localStorage.cartItems
        ? JSON.parse(localStorage.cartItems).filter((c) => c.item)
        : [];
    }
  },
  watch: {
    "$store.state.auth.cart"() {
      // обновляем localStorage при каждом изменении state.auth.cart
      localStorage.cartItems = this.$store.state.auth.cart ? JSON.stringify(this.$store.state.auth.cart) : [];
    },
    "$store.state.exchange_rates": {
      deep: true,
      handler() {
        // обновляем localStorage при каждом изменении exchange_rates
        localStorage.exchange_rates = JSON.stringify(this.$store.state.exchange_rates);
      },
    },
  },
  computed: {
    canShowPage() {
      return !Object.keys(this.$store.state._overlays).some((k) => this.$store.state._overlays[k]);
    },
  },
  components: {
    CookiesComponent,
    MobileMenuComponent,
    CatalogMenuComponent,
    FooterComponent,
    HeaderComponent,
    ModalComponent,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "SASAPLACE",
    // all titles will be injected into this template
    titleTemplate: "%s | Торговая площадка товаров из Турции",
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
