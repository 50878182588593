<template>
  <label
    class="input"
    :class="{
      'input--filled': !!value,
      'input--error': !!error,
      'input--icon-left': iconPosition === 'left',
      'input--icon-right': iconPosition === 'right',
      'input--disabled': disabled,
    }"
  >
    <span v-if="title" class="input__title">{{ title }}</span>
    <span class="input__container">
      <button class="input__action" type="button" v-if="iconPosition" @click="$emit('action')">
        <slot name="icon">
          <RemixIconComponent category="System" name="search-2-line" />
        </slot>
      </button>
      <input
        :type="type"
        v-mask="mask"
        :placeholder="placeholder"
        class="input__field"
        :min="min"
        :max="max"
        ref="input"
        :value="value"
        @input="handleInput"
        @blur="$emit('blur', $event)"
        @keydown.enter="$emit('keydown')"
        :disabled="disabled"
      />
    </span>
    <span v-if="subtitle" class="input__subtitle">{{ subtitle }}</span>
    <span v-if="error" class="input__error">{{ error }}</span>
  </label>
</template>
<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import { VueMaskDirective } from "v-mask";

export default {
  name: "InputComponent",
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    mask: {
      type: String,
    },
    type: {
      type: String,
      default() {
        return "text";
      },
    },
    placeholder: {
      type: [String, Number],
    },
    min: Number,
    max: Number,
    value: {
      type: [String, Number],
    },
    error: {
      type: String,
    },
    iconPosition: {
      type: String,
      validator(value) {
        return value === "left" || value === "right";
      },
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    handleInput(e) {
      let value = e.target.value;
      if (this.type === "number") {
        //   if (value < this.min) {
        //     this.$refs.input.value = this.min.toString();
        //     this.$emit("input", this.min);
        //   } else if (value > this.max) {
        //     this.$refs.input.value = this.max.toString();
        //     this.$emit("input", this.max);
        //   } else {
        //     this.$refs.input.value = value.toString();
        //     this.$emit("input", value);
        //   }
        this.$emit("input", value);
      } else {
        this.$refs.input.value = value.toString();
        this.$emit("input", value);
      }
    },
  },
  components: { RemixIconComponent },
  directives: {
    mask: VueMaskDirective,
  },
};
</script>

<style lang="stylus">
.input {
  display inline-flex
  flex-direction column
  gap 4px

  &__container {
    display flex
    position relative
    background: var(--white);
    border: 1px solid var(--blue-500);
    box-sizing: border-box;
    border-radius: var(--small_radius);
    height 44px
    transition var(--transition)
  }

  &:focus-within {
    & ^[0]__container {
      border-color var(--blue-900)
    }

    & .icon svg path {
      fill var(--body-color-muted)
    }
  }

  &--filled:not(:focus-within) {
    & ^[0]__container {
      border-color var(--blue-500)
    }

    & .icon svg path {
      fill var(--body-color-muted)
    }
  }

  &--error& {
    & ^[0]__container {
      border-color var(--red-500)
    }

    & .icon svg path {
      fill var(--red-500)
    }

    &__field {
      color: var(--red-500);

      &::placeholder {
        color: var(--red-50);
      }
    }
  }

  &--focused {
    & ^[0]__container {
      border-color var(--blue-900)
    }
  }

  &--error {
    & ^[0]__container {
      border-color var(--red-500)
    }
  }

  &--disabled {
    & ^[0]__container {
      background var(--blue-400)
    }
  }

  &--icon-left &__action {
    absolute left top
  }

  &--icon-left &__field {
    padding 12px 16px 12px 44px
  }

  &--icon-right &__action {
    absolute right top
  }

  &--icon-right &__field {
    padding 12px 44px 12px 16px
  }

  &__title {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--body-color-muted);
    white-space pre-line
  }

  &__subtitle {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 20px;
    color: var(--gray-50);
  }

  &__error {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 20px;
    color: var(--red-500);
  }

  &__action {
    background none
    border none
    height 100%
    display inline-flex
    align-items center
    padding 16px
  }

  &__field {
    border none
    background none
    width 100%
    height 100%
    padding 16px
    outline none
    font-weight: normal;
    font-size: 0.875em;
    color: var(--body-color-muted);

    &::placeholder {
      font-weight: normal;
      color var(--gray-50)
    }
  }

  .icon {
    width 20px
    height 20px

    svg path {
      transition var(--transition)
      fill var(--gray-50)
    }
  }
}
</style>
