<template>
  <ul class="catalog-list">
    <li v-for="(category, i) in categories" :key="i">
      <ImgComponent class="catalog-list__img" :head_img="category.head_img" width="200" height="200" />
      <h4 class="catalog-list__title">
        <router-link
          :to="{ name: 'catalog', params: { id: category.id }, query: { page_title: category.title } }"
        >
          {{ category.title }}
        </router-link>
      </h4>
      <ul class="catalog-list__categories">
        <li v-for="(child, index) in category.childs" :key="index">
          <router-link
            :to="{ name: 'catalog', params: { id: child.id }, query: { page_title: child.title } }"
          >
            {{ child.title }}
          </router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: "CatalogCategoryList",
  props: {
    categories: Array,
  },
  methods: {
    handleCategoryClick(item) {
      this.$router
        .push({
          name: "catalog",
          params: {
            id: item.id,
          },
        })
        .then(() => {
          this.$store.state._overlays.showCatalog = false;
        })
        .catch(() => {
          this.$store.state._overlays.showCatalog = false;
        });
    },
  },
};
</script>

<style lang="stylus">
.catalog-list {
  column-count: 3;
  column-gap: 24px;
  +below(1024px) {
    column-count 2
  }

  > li {
    display: flex;
    flex-direction: column;
    break-inside: avoid-column;
    /*display: inline-block;*/
    margin-bottom 24px
    gap 16px
  }

  &__img {
    background: var(--white);
    border: 1px solid var(--blue-500);
    box-sizing: border-box;
    border-radius: var(--main_radius);
    width 80px
    max-width 80px
    height 80px
  }

  &__title {
    font-weight: normal;
    font-size: 1.125em;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    margin 0

    a {
      color var(--gray-500);
    }
  }

  &__categories {
    a {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--gray-500);
      padding 8px 0
    }
  }
}
</style>
