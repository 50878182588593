<template>
  <div class="main-footer">
    <div class="main-footer__container">
      <div class="main-footer__top">
        <router-link
          :to="{ name: 'home' }"
          class="main-footer__logo"
          title="Торговая площадка товаров из Турции"
        >
          <!-- <FileIconComponent name="logo-white" /> -->
          <img src="/static/images/logo-white.png" alt="logo" />
        </router-link>
        <div class="main-footer__apps">
          <a v-for="(item, i) in apps" :key="i" :href="item.link" target="_blank" :title="item.title">
            <FileIconComponent :name="item.icon" />
          </a>
        </div>
      </div>
      <div class="main-footer__bottom">
        <SocialsComponent v-if="isMobile" :socials="marketplace.socials" key="asdf" />
        <nav id="contacts" class="main-footer__column">
          <h4 class="main-footer__column-title">Контакты</h4>
          <div class="main-footer__column-list">
            <span v-if="marketplace.address && marketplace.address.value">
              {{ marketplace.address.value }}
            </span>
            <span v-if="marketplace.schedule">
              {{ marketplace.schedule }}
            </span>
            <a href="https://yandex.ru/maps/-/CCUZYVCDOC" class="main-footer__link" target="_blank">
              Посмотреть на карте
            </a>
            <template v-for="(item, i) in marketplace.phones">
              <a :href="item.value | phone" class="main-footer__link" v-if="item && item.value" :key="i">
                {{ item.value }}
              </a>
            </template>
            <a
              :href="`mailto:${marketplace.email.value}`"
              class="main-footer__link"
              v-if="marketplace.email && marketplace.email.value"
            >
              {{ marketplace.email.value }}
            </a>
            <SocialsComponent v-if="!isMobile" :socials="marketplace.socials" />
          </div>
        </nav>
        <nav class="main-footer__column">
          <h4 class="main-footer__column-title">Покупателям</h4>
          <ul class="main-footer__column-list">
            <li>
              <router-link :to="{ name: 'marketplace' }">О компании</router-link>
            </li>
            <li v-for="(page, i) in pages" :key="i">
              <router-link :to="{ name: 'static', params: { link: page.link } }">
                {{ page.title }}
              </router-link>
            </li>
          </ul>
        </nav>
        <nav class="main-footer__column">
          <h4 class="main-footer__column-title">Магазинам</h4>
          <ul class="main-footer__column-list">
            <li>
              <router-link :to="{ name: 'marketplace', hash: '#claim' }">
                Приглашение к сотрудничеству
              </router-link>
            </li>
            <li>
              <a href="/admin">Вход в личный кабинет</a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import FileIconComponent from "components/FileIconComponent.vue";
import SocialsComponent from "components/SocialsComponent.vue";

export default {
  name: "MainFooterComponent",
  data() {
    return {
      isMobile: false,
      apps: [
        {
          title: "Google Play",
          icon: "google-play",
          link: "https://play.google.com/store/apps/details?id=com.app.sasa_mobile",
        },
        {
          title: "App Store",
          icon: "app-store",
          link: "https://apps.apple.com/ru/app/sasa/id1632755998?l=en",
        },
      ],
    };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 860;
  },
  computed: {
    pages() {
      return this.$store.state.pages;
    },
    communications() {
      return this.$store.state.marketplace.communications || [];
    },
    marketplace() {
      return {
        schedule: this.$store.state.marketplace.schedule,
        address: this.communications.find((item) => item?.type?.code === this.$store.state._types.ADDRESS),
        phones: this.communications.filter((item) => item?.type?.code === this.$store.state._types.PHONE),
        email: this.communications.find((item) => item?.type?.code === this.$store.state._types.EMAIL),
        socials: this.communications.filter((item) => item?.type?.code === this.$store.state._types.SOCIAL),
      };
    },
  },
  components: { SocialsComponent, FileIconComponent },
};
</script>

<style lang="stylus">
.main-footer {
  background: var(--gray-800);
  display flex
  justify-content center
  padding 24px 15px
  +below(860px) {
    padding 30px 15px
  }

  &__container {
    max-width var(--main_width)
    width 100%
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start;
  }

  &__top {
    display flex
    align-items center
    justify-content space-between
    width 100%
    padding 24px 0
    border-bottom 1px solid var(--gray-600)
    +below(860px) {
      flex-direction: column;
      gap 30px
      padding 0 0 24px
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__logo {
    width: 125px;
    height 40px
    display: flex
    align-items: center
  }

  &__apps {
    display flex
    gap 16px
    align-items center

    a {
      display inline-flex

      .icon {
        height 40px
        width 120px
      }
    }
  }

  &__bottom {
    padding 24px 0
    display flex
    justify-content flex-start
    align-items flex-start
    gap 100px

    > .socials {
      display none
    }

    +below(860px) {
      flex-direction column
      gap 30px

      > .socials {
        display flex
      }
    }
  }

  &__column {
    display flex
    flex-direction column
    gap 16px
  }

  &__column-title {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: var(--white);
    margin-bottom 0
  }

  &__column-list {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 8px

    > .socials {
      +below(860px) {
        display none
      }
    }

    span {
      color var(--white)
    }

    a {
      font-weight: normal;
      font-size: 0.875em;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: var(--blue-500);
    }
  }

  &__link {
    text-decoration underline
  }
}
</style>
