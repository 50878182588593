import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default function createRouter() {
  const routes = [
    {
      path: "/",
      name: "home",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "/thank-you",
      name: "thank-you",
      component: () => import("./views/ThankYou.vue"),
      // meta: {
      //   authRequired: true,
      // },
    },
    {
      path: "/cart",
      name: "cart",
      component: () => import("./views/cart/index.vue"),
    },
    {
      path: "/catalog/:id?",
      name: "catalog",
      component: () => import("./views/catalog/index.vue"),
    },
    {
      path: "/product/:id/:link?",
      name: "product",
      component: () => import("./views/product/index.vue"),
    },
    {
      path: "/company/:link",
      name: "company",
      component: () => import("./views/home/index.vue"),
    },
    {
      path: "/shares",
      name: "shares",
      component: () => import("./views/shares/list/index.vue"),
    },
    {
      path: "/shares/:id",
      name: "share",
      component: () => import("./views/shares/open/index.vue"),
    },
    {
      path: "/shops",
      name: "shops",
      component: () => import("./views/shops/list/index.vue"),
    },
    {
      path: "/shops/:id",
      name: "shop",
      component: () => import("./views/shops/open/index.vue"),
    },
    {
      path: "/marketplace",
      name: "marketplace",
      component: () => import("./views/marketplace/index.vue"),
    },
    {
      path: "/user/:id?",
      name: "user",
      component: () => import("./views/user/index.vue"),
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("./views/faq/index.vue"),
    },
    {
      path: "/static/:link",
      name: "static",
      component: () => import("./views/static/index.vue"),
    },
    {
      path: "*",
      name: "notfound",
      component: () => import("./views/PageNotFound.vue"),
    },
  ];
  return new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      if (to.hash) {
        return {
          selector: to.hash,
          behavior: "smooth",
        };
      }
      return { x: 0, y: 0 };
    },
    fallback: true,
    routes,
  });
}
